// Bootstrap
$enable-caret: true;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: true;
$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: false;
$enable-grid-classes: true;
$enable-pointer-cursor-for-buttons: true;
$enable-print-styles: false;
$enable-responsive-font-sizes: true;
$enable-validation-icons: true;
$enable-deprecation-messages: true;

$grid-columns: 12;

$gray-200: #eaeaea;
$blue: #2491ce;
$dark-blue: #062133;
$green: #008f34;
$red: #d14946;
$yellow: #e8b141;
$cyan: #4abbe8;
$primary: $dark-blue;

$zindex-modal: 512;
$zindex-modal-backdrop: $zindex-modal;
$modal-backdrop-bg: rgba(black, .5);
$modal-backdrop-opacity: 1;

// Select2
$s2bs-padding-base-vertical: .2rem;
$s2bs-padding-base-horizontal: 1rem;
$s2bs-padding-small-horizontal: .5rem;
$s2bs-font-size-base: 1rem;

// Loader
$spinner-size: 4rem;
